import React from 'react'
import PropTypes from 'prop-types'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => { this.props.onCloseArticle() }}></div>
    return (
      <div id="main" style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}>
        <article id="business" className={`${this.props.article === 'business' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h1 className="major">BUSINESS DEVELOPMENT</h1>
          <h2>Introduction</h2>
          <h3>WE ARE AN EXPERIENCED CONSULTING AGENCY IN BUSINESS STRATEGY AND PLAN DEVELOPMENT.</h3>
                  <p>
                    With our vast array of professional services including business planning, 
                    business finance strategy, operational modelling and process optimization, 
                    we can help your enterprise translate its objective strategies into 
                    actionable blueprints. We take a data-driven approach that supports 
                    and complements, rather than contradict, your business leaders' 
                    specific market-specialized experience. We bring a results-oriented, 
                    merit and metric-based evaluation scheme so that you can easily see 
                    where your business is excelling and where improvements can be made.
                  </p>
                  <p>
                    Leveraging our home-grown business planning software suite we have parterned with 
                    institutions the world over in developing vision-based strategies that bring 
                    greater client satisfaction, free underutilized capital, empower business 
                    descion makers with the data they need, and establish new efficient and 
                    managemenable practices designed to last.
                  </p>
                  <p>
                  Contact us now to get an idea for how we can help you improve your business's outlook: Email <a href="mailto:info@qyutech.com">info@qyutech.com</a>
                  </p>   
          {close}
        </article>

        <article id="finance" className={`${this.props.article === 'finance' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">FINANCIAL MANAGEMENT</h2>
          <h3>NO BUSINESS LASTS WITHOUT A FUNDAMENTALLY BALANCED AND ACCURATE FINANCIAL MANAGEMENT STRATEGY.</h3>
                  <p>
                  Through our vast expertise and experience counseling market finance institutions and financial technology firms, we help our clients to develop and implement money and risk management strategies that supports their vision and exploits their competitive edge. Whether it be capital preservation, risk assesment, statistical analysis, scenario-driven practices or hands-on training, we find and employ the tools that improve bottom-line performance.
                  </p>
                  <p>
                  Ready to make your money go further than you ever though it could? Email <a href="mailto:info@qyutech.com">info@qyutech.com</a>
                  </p>
          {close}
        </article>

        <article id="product" className={`${this.props.article === 'product' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h1 className="major">PRODUCT DESIGN</h1>
          <h3>CUSTOMER-FOCUS DESIGN IN THE FINANCE INDUSTRY MEANS MORE THAN POSTIVE NET RETURNS.</h3>
          <p>
          Our team of experts use detailed diagnostics to define market needs and evaluate client goals and capacity to help structure new products that address market gaps and turn public policy directives into pragmatic and effective financial solutions. With a background in feasibility studies, and expertise in complex financial mechanisms, we bring exceptional value, insight, and precision to the design of our clients’ products and programs.
          </p>
          <p>
          Wow your customers. Email <a href="mailto:info@qyutech.com">info@qyutech.com</a>
          </p>       
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">Contact</h2>
          <div>
            <strong>Mailing address</strong>
            <p>
              Office 2204, 22nd floor <br />
              PH Sortis Business Tower <br />
              Panama City, Panama <br />
              United States of America
            </p>
            <strong>Email</strong>
            <p>
              <a href="mailto:info@qyutech.com">info@qyutech.com</a>
            </p>
            <strong>Phone</strong>
            <p>
              <a href="tel:+12143770380">+1 (507) 202-6898</a>
            </p>
          </div>

          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool
}

export default Main