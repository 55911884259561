import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Header = (props) => {
  
  const content = (
  <div className="content">

    <div className="inner">
        <h1>Qyu Technologies CTA</h1>
        <p>BUSINESS CONSULTING EXPERTS SPECIALIZING IN BUSINESS DEVELOPMENT, FINANCIAL MODELING, AND PRODUCT DESIGN.</p>
    </div>
  </div>);
  const nav = (
  <nav>
      <ul>                
          <li><span className="link" onClick={() => {props.onOpenArticle('business')}}>BUSINESS DEVELOPMENT</span></li>
          <li><span className="link" onClick={() => {props.onOpenArticle('finance')}}>FINANCIAL MANAGEMENT</span></li>
          <li><span className="link" onClick={() => {props.onOpenArticle('product')}}>PRODUCT DESIGN</span></li>
          <li><span className="link" onClick={() => {props.onOpenArticle('contact')}}>Contact</span></li>
      </ul> 
      
  </nav>
  );

  return (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
      {content}{nav}
    </header>
)}

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
